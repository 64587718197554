<template>
  <UsersList/>
  
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
import UsersList from './components/UsersList.vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faLocationDot,
  faPhone
} from "@fortawesome/free-solid-svg-icons"

library.add(faEnvelope, faLocationDot, faPhone)

export default {
  name: 'App',
  components: {
    UsersList
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
