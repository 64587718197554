<template>
    <div class="section section-user-list flex flex-col items-center px-5">
        <div class="container">
            <div class="user-lists grid grid-cols-4 grid-cols-none md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                <div v-for="( user, index ) in users" :key="index" class="user-list flex flex-col flex-nowrap items-center">
                    <div class="user-img-wrap w-full flex flex-col flex-nowrap items-center rounded-xl overflow-hidden">
                        <img :src="user.picture.large" alt="User Avatar" class="user-img w-full object-cover">
                    </div>
                    <div class="user-details bg-white p-5 flex flex-col w-full items-start gap-3">
                        <div class="user-detail user-name font-bold text-lg">{{ user.name.first }} {{ user.name.last }}</div>
                        <div class="user-detail user-address text-base font-normal flex flex-row gap-2 items-center">
                            <font-awesome-icon icon="location-dot" />
                            <span class="text-left">{{ user.location.street.number }} {{ user.location.street.name }}, {{ user.location.city }}, {{ user.location.state }}, {{  user.country }} {{  user.location.postcode }}</span>
                        </div>
                        <div class="user-detail user-address text-base font-normal flex flex-row gap-2 items-center">
                            <font-awesome-icon icon="envelope" />
                            <a class="text-left" :href="'mailto:' + user.email" target="_blank">{{ user.email }}</a>
                        </div>
                        <div class="user-detail user-address text-base font-normal flex flex-row gap-2 items-center">
                            <font-awesome-icon icon="phone" />
                            <a class="text-left" :href="'tel:' + user.phone" target="_blank">{{ user.phone }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)

export default {
    name: 'UsersList',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            users: []
        }
    },
    mounted() {
        this.fetchRandomUsers();
    },
    methods: {
        fetchRandomUsers() {
            axios.get('https://randomuser.me/api/?results=20')
                .then(response => {
                    this.users = response.data.results;
                    console.log(this.users);
                })
                .catch(error => {
                    console.log('Error fetching users:', error);
                })
        }
    }
}

</script>